import React from 'react'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { css } from 'glamor'
import { Subtitle2 } from '@toriihq/design-system'

const CSS = {
  main: css({
    padding: '16px',
    border: `1px solid ${colors.border}`,
    backgroundColor: colors.white,
    transform: 'translateX(-90px) translateY(-20px)'
  }),
  header: css({
    marginBottom: '4px',
    textTransform: 'uppercase'
  }),
  details: css(texts.headers.regular, {
    textTransform: 'uppercase'
  }),
  noData: css(texts.headers.regular, {
    color: colors.grey1
  })
}

const CustomTooltip = ({ payload, active, valueDisplayFunction }) => {
  if (!active) {
    return null
  }

  const { name, value } = payload[0].payload
  return (
    <div {...CSS.main}>
      <div {...CSS.header}><Subtitle2>{name}</Subtitle2></div>
      {value === null ? <div {...CSS.noData}>Missing data</div> : <span {...CSS.details}>{valueDisplayFunction(value, payload[0].payload)}</span>}
    </div>
  )
}

CustomTooltip.defaultProps = {
  valueDisplayFunction: (value) => value
}

export default CustomTooltip
