import { css } from 'glamor'

export const Main = css({
  padding: '10px 10px 0px 10px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

export const Description = css({
  width: '700px',
  marginTop: '16px'
})
