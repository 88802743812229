import React from 'react'
import { withTheme } from 'styled-components'
import { css } from 'glamor'
import { Checkbox, Spacer, Subtitle2 } from '@toriihq/design-system'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import Placeholder from '../placeholder'
import TableSearch from '../table/tableSearch'
import FieldByType from './fieldByType'

const generateCSS = ({ theme }) => {
  return {
    main: css({
      border: `1px solid ${theme.palette.border.primary}`,
      borderRadius: '8px',
      ' .ef-field': {
        borderBottom: '0'
      },
      ' .ef-input': {
        display: 'inline-block',
        width: '90%'
      },
      ' textarea.ef-input': {
        height: '100px'
      },
      marginBottom: '28px'
    }),
    header: css({
      paddingBottom: '20px',
      textTransform: 'uppercase'
    }),
    actions: css({
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px'
    }),
    search: css({
      flex: 1
    }),
    groupHeader: css({
      padding: '20px',
      textTransform: 'uppercase'
    }),
    file: css({
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    }),
    fileIcon: css({
      marginRight: '10px'
    })
  }
}

class Details extends React.Component {
  CSS = generateCSS({ theme: this.props.theme })
  state = { hideEmptyFields: false, search: '' }

  onSearch = (e) => {
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
  }

  toggleHideEmptyFields = () => {
    const { hideEmptyFields } = this.state
    this.setState({ hideEmptyFields: !hideEmptyFields })
  }

  emptyFieldFilter = (detail) => {
    const { hideEmptyFields } = this.state
    return !hideEmptyFields || Boolean(get(detail, ['values', 0], ''))
  }

  searchFilter = (detail) => {
    const { search } = this.state
    const searchTerm = search.toLowerCase()
    return !searchTerm || detail.name.toLowerCase().includes(searchTerm)
  }

  renderAppDetailsGroup (details, label) {
    const { loading, readonly, disabled, currencySymbol, usersById, editable, removable } = this.props

    const filteredDetails = details
      .filter(this.emptyFieldFilter)
      .filter(this.searchFilter)

    return (
      <div key={label} {...this.CSS.main}>
        <div {...this.CSS.groupHeader}><Subtitle2>{label}</Subtitle2></div>
        <div>
          {filteredDetails.map(detail => {
            const value = get(detail, ['values', 0])
            const onSave = value => this.props.onSave(detail.idField, value)
            const onRemove = () => this.props.onRemove(detail.idField)
            const onHistory = () => this.props.onHistory(detail)

            if (loading) {
              return (
                <Placeholder key={detail.idField} loading={loading} rows={1} style={{ maxWidth: '95%', margin: '20px' }}>
                  <div />
                </Placeholder>
              )
            }

            return <FieldByType
              key={detail.idField}
              currencySymbol={currencySymbol}
              usersById={usersById}
              loading={loading}
              readonly={readonly}
              disabled={disabled || detail.isPluginGroup !== 0 || detail.isReadOnlyGroup}
              field={detail}
              identifier={detail.idField}
              label={detail.name}
              value={value}
              onSave={onSave}
              onRemove={onRemove}
              ignoreEditMode={!editable}
              removable={removable}
              onHistory={onHistory}
              showHistoryButton
            />
          })}
        </div>
      </div>
    )
  }

  render () {
    const { groups, details, header } = this.props

    if (!details) {
      return null
    }

    const detailsByGroups = groupBy(details, 'idGroup')
    return (
      <div>
        <div {...this.CSS.header}><Subtitle2>{header}</Subtitle2></div>
        <div {...this.CSS.actions}>
          <TableSearch onSearch={this.onSearch} overrideStyle={this.CSS.search} />
          <Spacer left={'space-300'}>
            <Checkbox label='Hide blank fields' onChange={this.toggleHideEmptyFields} checked={this.state.hideEmptyFields} />
          </Spacer>
        </div>
        {
          groups
            .filter(group => Boolean(detailsByGroups[group.id]))
            .map(group => this.renderAppDetailsGroup(detailsByGroups[group.id], group.label))
        }
      </div>
    )
  }
}

export default withTheme(Details)
