import React, { useState, useEffect } from 'react'
import ToriiSelect from '../select'
import orderBy from 'lodash/orderBy'
import { getAllOrgs } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getOrgs } from '@selectors/orgs'
import * as Style from './style'
import Spinner from '@media/spinner.svg'
import { Icon, Stack } from '@toriihq/design-system'

const SelectOrgs = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const orgs = useSelector(getOrgs)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getAllOrgs())
  }, [dispatch])

  const spinner = <img alt='Loading...' src={Spinner} width='30px' height='30px' />

  const onChangeSwitchOrgPage = (event) => {
    setIsLoading(true)
    window.location.href = `/team/${event ? event.value : ''}`
  }

  const getOptions = () => {
    const allOrgs = orgs
      .filter(org => org.companyName)
      .map((org) => ({
        label: `${org.companyName} - ${org.id}`,
        value: org.id,
        icon: org.hasWritePermissions ? 'Edit' : 'Eye'
      }))
    return orderBy(allOrgs, [org => org.label.toLowerCase()], ['asc'])
  }

  const optionRenderer = (option) => {
    return <Stack direction='row' gap='space-100'>
      <Icon name={option.data.icon} />
      <span>{option.label}</span>
    </Stack>
  }

  const options = getOptions()
  const icon = options.find(option => option.value === idOrg)?.icon

  return (
    <Style.Main>
      <ToriiSelect
        options={options}
        optionRenderer={optionRenderer}
        onChange={onChangeSwitchOrgPage}
        clearable={false}
        placeholder=''
        value={idOrg}
        backspaceRemoves={false}
        tabSelectsValue={false}
        borderless
        menuWidth={300}
        icon={icon}
      />
      {isLoading && spinner}
    </Style.Main>
  )
}

export default SelectOrgs
