import React from 'react'
import pluralize from 'pluralize'
import RiskIcon from '../../../riskIcon'
import { Subtitle2, Body2 } from '@toriihq/design-system'
import * as Style from './style'

const CustomTooltip = ({ payload, active }) => {
  if (!active || !payload.length) {
    return null
  }

  const { name, value } = payload[0]

  const riskLevel = {
    Low: 1,
    Medium: 2,
    High: 3
  }[name]

  if (!riskLevel) {
    return null
  }

  return (
    <Style.Main>
      <Style.Header><RiskIcon riskLevel={riskLevel} /> <Subtitle2>{name}</Subtitle2></Style.Header>
      <Body2>{`${pluralize('app', value, true)}`}</Body2>
    </Style.Main>
  )
}

export default CustomTooltip
