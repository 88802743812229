import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'
import * as Style from './style'
import { OneLiner } from '@shared/style/oneLiner'
import { Subtitle2, Stack } from '@toriihq/design-system'

const Tooltip = ({ payload, active, hideOnZeroValue = false, formatter = value => value, fill, data }) => {
  if (!active) {
    return null
  }

  const { date, value: totalAmount, categories, tooltipSubHeader } = get(payload, ['0', 'payload'], {})

  if (hideOnZeroValue && !totalAmount) {
    return null
  }

  return (
    <Style.Container>
      <Style.HeaderContainer>
        <Style.Header><Subtitle2>{moment.utc(date).format('MMMM YYYY')}</Subtitle2></Style.Header>
        <Style.SubHeader><OneLiner>{tooltipSubHeader}</OneLiner></Style.SubHeader>
      </Style.HeaderContainer>

      {map(categories, (amount, categoryName) => renderCategory({ categoryName, amount, totalAmount, fill, formatter, date, data }))}
      <Style.Total>
        <Stack direction='row' justifyContent='space-between'>
          <Subtitle2>Total</Subtitle2>
          <Subtitle2>{formatter(totalAmount)}</Subtitle2>
        </Stack>
      </Style.Total>
    </Style.Container>
  )
}

const renderCategory = ({ categoryName, amount, totalAmount, fill, formatter, date, data }) => {
  const precentage = amount / totalAmount * 100
  const categoriesCount = (data.find(category => category.date === date) || {}).categoriesCount
  const count = (categoriesCount || {})[categoryName]

  return (
    <Style.CategoryRow key={categoryName}>
      <Style.InfoContainer>
        <Style.CategoryName>{categoryName} {count && `(${count})`}</Style.CategoryName>
        <Subtitle2>{formatter(amount)}</Subtitle2>
      </Style.InfoContainer>
      <Style.BarContainer>
        <Style.Bar width={`${precentage}%`} color='brandPrimary' />
        <Style.Bar width={`${100 - precentage}%`} color='disabled' />
      </Style.BarContainer>
    </Style.CategoryRow>
  )
}

export default Tooltip
