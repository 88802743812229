import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const MANDATORY_OPTIONS_AMOUNT = 1
export const MAX_FIELD_NAME_LENGTH = 100
const SIDE_PADDING = '35px'

export const Divider = css({
  borderTop: `1px solid ${colors.border}`,
  margin: `0 ${SIDE_PADDING} 20px ${SIDE_PADDING}`
})

export const DeclineButton = css({
  marginRight: '10px'
})

export const Icon = css({
  fontSize: '16px'
})

export const DeleteButton = css({
  opacity: 0
})

export const ErrorMessage = css(texts.body, {
  color: colors.red
})

export const OptionContainer = css({
  width: '100%',
  backgroundColor: colors.white,
  border: `1px solid ${colors.lightBlue2}`,
  borderRadius: '3px',
  margin: '10px 0',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '2px 10px',
  boxSizing: 'border-box',
  height: '54px',
  cursor: 'not-allowed',
  '&:hover': {
    ' .deleteButton': {
      opacity: 1
    }
  }
})

export const DraggableOptionContainer = css({
  '&:hover': {
    cursor: 'move',
    ' .indexField': {
      display: 'none'
    },
    ' .dotsField': {
      display: 'flex'
    }
  }
})

export const IndexField = css({
  display: 'block',
  width: '30px'
})

export const DotsField = css({
  display: 'none'
})
