import styled from 'styled-components'

export const Container = styled.div`
  width: 235px;
  padding: 12px 16px 18px 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  background-color: ${({ theme }) => theme.palette.background.primary};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  margin-bottom: 8px;
  padding-bottom: 5px;
`

export const Header = styled.div`
  text-transform: uppercase;
  margin-bottom: 3px;
`

export const SubHeader = styled.div`
  margin-bottom: 3px;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const CategoryRow = styled.div`
  margin-bottom: 10px;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CategoryName = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const BarContainer = styled.div`
  display: flex;
`

export const Bar = styled.div<{ width: string; color: string }>`
  border: 1px solid;
  width: ${({ width }) => width};
  height: 2px;
  color: ${({ theme, color }) => theme.palette.background[color]};
`

export const Total = styled.div`
  margin-top: 18px;
`
