import React from 'react'
import Table from '../table/index'
import { css } from 'glamor'
import { Icon, RadioButton, Tooltip, Subtitle2, Stack } from '@toriihq/design-system'
import EnableFor from '../enableFor/'
import { SCOPES, SCOPES_PERMISSIONS, TOOLTIP_ALERT } from '../../constants'

const HIDDEN_SCOPES = ['members-and-roles', 'api-management', 'dashboards']

const SCOPES_TOOLTIP = {
  automation: {
    label: 'Workflows Logs may expose user attributes or finance data',
    alert: TOOLTIP_ALERT.NONE
  }
}

const CSS = {
  radio: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  optionDescription: css({
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal'
  })
}

class ScopesSelectionTable extends React.Component {
  onScopeClick = (id, permission) => {
    const { onChange, scopes, isReadOnly } = this.props
    if (isReadOnly) return
    const updateScopes = scopes.map(scope => (scope.id === id ? { ...scope, permission } : scope))
    onChange && onChange(updateScopes)
  }

  renderRadio = (permission, scopePermission, scopeName) => {
    const { isReadOnly } = this.props
    const isChecked = permission === scopePermission
    const radioBtn = <RadioButton checked={isChecked} onChange={() => this.onScopeClick(scopeName, scopePermission)} disabled={isReadOnly} />
    return (
      <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]} allowForToriiAdmin showAsDisabled={false}>
        { radioBtn }
      </EnableFor>
    )
  }

  renderTooltipForScope = (scopeWithTooltips) => {
    if (!scopeWithTooltips) return null

    return (
      <Tooltip label={scopeWithTooltips.label} alert={scopeWithTooltips.alert}>
        <Icon name='Info' />
      </Tooltip>
    )
  }

  canRenderNoneScope = (scopesValues, id, permission) => {
    const { isReadOnly } = this.props
    const isNoneScope = scopesValues.includes(`${id}:none`)
    // if we're in read-only and this scope is checked
    const isReadOnlyAndChecked = isReadOnly && permission === SCOPES_PERMISSIONS.NONE
    // if it's a no-access of a non-financial scope

    return isNoneScope || isReadOnlyAndChecked
  }

  getColumns = () => {
    const { isSmallScreen = false } = this.props
    const scopesValues = Object.values(SCOPES)

    return [
      {
        Header: 'Scope',
        id: 'scopeInfo',
        sortable: false,
        resizable: false,
        Cell: ({ row: { name, description, id } }) => (
          <div {...CSS.optionDescription} key={name}>
            <Stack direction='row' align='center' gap='space-025'>
              <Subtitle2>{name}</Subtitle2>
              { this.renderTooltipForScope(SCOPES_TOOLTIP[id]) }
            </Stack>
            <Subtitle2 color='secondary'>{description}</Subtitle2>
          </div>
        )
      },
      {
        Header: 'No Access',
        id: 'noScope',
        sortable: false,
        resizable: false,
        width: isSmallScreen ? 82 : 108,
        Cell: ({ row: { id, permission } }) => {
          return this.canRenderNoneScope(scopesValues, id, permission) ? <div {...CSS.radio} >
            { this.renderRadio(permission, SCOPES_PERMISSIONS.NONE, id) }
          </div> : <span {...CSS.radio}>-</span>
        }
      },
      {
        Header: 'View only',
        id: 'readScope',
        sortable: false,
        resizable: false,
        width: isSmallScreen ? 82 : 108,
        Cell: ({ row: { id, permission } }) => {
          return scopesValues.includes(`${id}:read`) ? (<div {...CSS.radio} >
            { this.renderRadio(permission, SCOPES_PERMISSIONS.READ, id) }
          </div>) : <span {...CSS.radio}>-</span>
        }
      },
      {
        Header: 'Take action',
        id: 'writeScope',
        sortable: false,
        resizable: false,
        width: isSmallScreen ? 82 : 108,
        Cell: ({ row: { id, permission } }) => {
          return scopesValues.includes(`${id}:write`) ? (<div {...CSS.radio} >
            { this.renderRadio(permission, SCOPES_PERMISSIONS.WRITE, id) }
          </div>) : <span {...CSS.radio}>-</span>
        }
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'name',
        show: false
      },
      {
        accessor: 'description',
        show: false
      },
      {
        accessor: 'permission',
        show: false
      }
    ]
  }

  render () {
    const { scopes = [], loading = false } = this.props
    const filteredScopes = scopes.filter(scopeInfo => !HIDDEN_SCOPES.includes(scopeInfo.id))

    return (
      <Table
        data={filteredScopes}
        columns={this.getColumns()}
        loading={loading}
        draggable={false}
      />
    )
  }
}

export default ScopesSelectionTable
