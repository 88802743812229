import React from 'react'
import { css } from 'glamor'
import moment from 'moment'
import colors from '../../shared/style/colors'
import ReportHeader from '../../components/reportHeader'
import Table from '../table'
import { TABLES, SCOPES, ITEMS_PER_PAGE } from '../../constants'
import UserDetails from '../userDetails'
import { Tooltip, AlertBox, AlertBoxType, Subtitle2 } from '@toriihq/design-system'
import BrowserCounter from './browserCounter'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import pluralize from 'pluralize'
import EnableFor from '../enableFor'
import sumBy from 'lodash/sumBy'
import { sortStringFromArray } from '@shared/utils'
import debounce from 'lodash/debounce'
import { exportExtensionStatusCsv } from '@shared/reports'
import ChromeLogo from '@media/Chrome_logo.svg'
import FireFoxLogo from '@media/Firefox_logo.svg'
import EdgeLogo from '@media/Edge_logo.svg'
import keyBy from 'lodash/keyBy'

const BROWSERS = [{ id: 'chrome', logo: ChromeLogo }, { id: 'firefox', logo: FireFoxLogo }, { id: 'edge', logo: EdgeLogo }]
const browsersById = keyBy(BROWSERS, 'id')

const CSS = {
  wrapper: css({
    height: '100%'
  }),
  summaryContainer: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0 20px 0',
    border: `1px solid ${colors.border}`,
    borderRadius: '10px'
  }),
  summaryTitle: css({
    lineHeight: '20px',
    fontWeight: fontWeight.semiBold,
    textTransform: 'uppercase',
    marginBottom: '5px'
  }),
  summarySubTitle: css({
    lineHeight: '20px',
    fontWeight: fontWeight.normal
  }),
  summaryHeader: css({
    borderBottom: `1px solid ${colors.border}`,
    padding: '8px 20px'
  }),
  summary: css({
    display: 'flex',
    padding: '20px',
    '@media(max-width: 768px)': {
      display: 'block',
      padding: '0 20px'
    }
  }),
  browserLogo: css({
    width: '17px',
    height: '17px'
  }),
  status: css({
    textTransform: 'capitalize'
  }),
  alertBoxText: css({
    color: colors.black,
    fontSize: fontSize.large
  }),
  alertBoxTextExcludingLink: css({
    marginRight: '11px'
  })
}

class ExtensionRolloutReport extends React.Component {
  state = { sort: this.props.defaultSort }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchInitialData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchInitialData()
    }
  }

  fetchInitialData () {
    const { idOrg, getBrowserExtensionActivationUsers, getBrowserUsersCount } = this.props

    this.fetchData(true)
    BROWSERS.map(({ id }) => getBrowserUsersCount({ idOrg, browser: id }))
    getBrowserExtensionActivationUsers({ idOrg })
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props

    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }, 500)

  getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false }) => {
    const { idOrg, getExtensionReportUsers } = this.props
    const { sort } = this.state

    getExtensionReportUsers({ idOrg, limit, offset, sort: sortStringFromArray(sort), reset, q: this.query })
  }

  onSortedChange = (sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 250)

  getColumns () {
    return [
      {
        Header: 'Name',
        id: 'fullName',
        accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
        Cell: ({ row: { firstName, lastName, id, email, isExternal, photoUrl } }) => (
          <UserDetails
            idUser={id}
            firstName={firstName}
            lastName={lastName}
            email={email}
            isExternal={isExternal}
            showExternalUserBadge={false}
            photoUrl={photoUrl}
          />
        ),
        minWidth: 220,
        maxWidth: 300
      },
      {
        Header: 'Last report',
        accessor: 'lastReportedTime',
        Cell: ({ row: { lastReportedTime, lastReportedText } }) => (
          <Tooltip
            placement='top'
            label={moment(lastReportedTime).format('dddd, D MMMM YYYY')}
            hide={!lastReportedTime}>
            <span>{lastReportedText}</span>
          </Tooltip>
        ),
        minWidth: 220,
        maxWidth: 300
      },
      {
        Header: 'Browser',
        accessor: 'browser',
        Cell: ({ value: browser }) => (browser &&
          <Tooltip
            placement='top'
            label={browser}>
            <img alt={browser} src={browsersById[browser.toLowerCase()].logo} {...CSS.browserLogo} />
          </Tooltip>
        ) || '-'
      },
      {
        Header: 'Torii Lifecycle Status',
        accessor: 'lifecycleStatus',
        Cell: ({ value }) => <span {...CSS.status}>{value}</span>
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'lastReportedText',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ]
  }

  render () {
    const { idOrg, users, loading, browserActivationEmailUsersCount, toggleConfigureBrowserActivationEmailPopup, browsersCounts, total, loadingMore } = this.props
    const { sort } = this.state

    const allUsersCount = sumBy(Object.values(browsersCounts), browser => browser ? browser.count : 0)
    const loadingBrowsers = Object.values(browsersCounts).some(browser => browser.loading)

    return (
      <div {...CSS.wrapper}>
        <ReportHeader title='Extension status' onExportCSV={() => exportExtensionStatusCsv({ idOrg, sort })} />
        {Boolean(browserActivationEmailUsersCount) && (
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              title={`${pluralize('user', browserActivationEmailUsersCount, true)} did not report during the last 30 days.`}
              description='We suggest sending them an email to activate their Torii browser extension.'
              secondaryButton={{
                label: 'Configure activation email',
                onClick: () => toggleConfigureBrowserActivationEmailPopup({ isOpen: true })
              }}
            />
          </EnableFor>
        )
        }
        <div {...CSS.summaryContainer}>
          <div {...CSS.summaryHeader}>
            <div {...CSS.summaryTitle}><Subtitle2>Reporting Users</Subtitle2></div>
            <div {...CSS.summarySubTitle}><Subtitle2 color='secondary'>Last 30 Days</Subtitle2></div>
          </div>
          <div {...CSS.summary}>
            <BrowserCounter
              header='TOTAL'
              number={allUsersCount}
              loading={loadingBrowsers}
            />
            {BROWSERS.map(({ id, logo }) => (<BrowserCounter
              key={id}
              header={id}
              number={browsersCounts[id] && browsersCounts[id].count}
              loading={loadingBrowsers}
              src={logo}
            />))}
          </div>
        </div>
        {!loading && <div id='printIndication' />}
        <Table
          style={{ border: '1px solid transparent' }}
          tableKey={TABLES.extensionRolloutReportTable.key}
          data={users}
          columns={this.getColumns()}
          loading={loading}
          loadingMore={loadingMore}
          fetchData={this.fetchData}
          manual
          searchable
          forceShowSearch
          onSearch={this.onSearch}
          onSortedChangeCB={this.onSortedChange}
          totalCount={total}
        />
      </div>
    )
  }
}

export default ExtensionRolloutReport
