import styled from 'styled-components'

export const Main = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.primary};
  transform: translateX(-90px) translateY(-20px);
`

export const Header = styled.div`
  display: flex;
  width: 100px;
  margin-bottom: 4px;
  border-radius: ${({ theme }) => theme.corner('radius-050')};
  text-transform: uppercase;
`
